const IMAGES = document.getElementsByClassName('image');
let idx = 0;

function switchImage() {
    idx = idx > IMAGES.length - 1 ? 0 : idx;

    if (idx > 0) {
        regularRun();
    } else {
        if (IMAGES[IMAGES.length - 1].classList.contains('image-hidden')) {
            firstRun();
        } else {
            lastRun();
        }
    }

    idx++;
}

function firstRun() {
    IMAGES[idx].classList.toggle('image-hidden');
}

function regularRun() {
    firstRun();
    IMAGES[idx -1].classList.toggle('image-hidden');
}

function lastRun() {
    firstRun();
    IMAGES[IMAGES.length - 1].classList.toggle('image-hidden');
}

switchImage();

setInterval(switchImage, 7000);
